import React from 'react';

import Recursive from 'components/Recursive';
import Iterative from 'components/Iterative';

const App = () => {
  return (
    <div className="App">
      <Recursive />
      <Iterative />
    </div>
  );
};

export default App;
