import React, { useState } from 'react';

import mocks from '../../mocks';

const Recursive = () => {
  const [value, setValue] = useState({});
  const [categories, setCategories] = useState(mocks);

  const addCat = (parentId) => {
    const uniqueId = new Date().valueOf();
    let arr = categories;

    const updateArray = (cats) => {
      return cats.map((child) => {
        if (child.id === parentId) {
          child.children.push({
            id: uniqueId,
            title: value[child.id],
            children: [],
          });
        }

        if (child.children.length) {
          updateArray(child.children);
        }

        return child;
      });
    };

    setValue((prevState) => ({
      ...prevState,
      [parentId]: '',
    }));

    updateArray(arr);
    setCategories(arr);
  };

  const handleChange = (e, id) => {
    const val = e.target.value;

    setValue((prevState) => ({
      ...prevState,
      [id]: val,
    }));
  };

  const renderInput = (id) => {
    return (
      <div>
        <input
          type="input"
          value={value[id] || ''}
          onChange={(e) => handleChange(e, id)}
        />
        <button type="button" onClick={() => addCat(id)}>
          Add
        </button>
      </div>
    );
  };

  const renderCategories = (child) => {
    return child.map(({ id, title, children }, i) => {
      return (
        <ul key={id}>
          <li>
            <span>{title}</span>
            {renderInput(id)}
            {children.length ? renderCategories(children) : null}
          </li>
        </ul>
      );
    });
  };

  return renderCategories(categories);
};

export default Recursive;
